/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { ThemeProvider } from 'styled-components';

import theme from '../stylesheets/theme';

import Layout from '../components/layout';

const FaqStyles = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  a {
    color: ${(props) => props.theme.blue};
  }
  .faq-title {
    text-align: center;
    margin: 50px auto 25px;
    padding: 0 25px;
  }
  .faq-header-image {
    width: 100%;
    max-height: 800px;
    max-width: 1400px;
    margin: 58px auto 0;
  }
  .faq-content-container {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 15px auto;
    padding: 0 25px;
    .faq-description {
      /* margin-bottom: 30px; */
    }
    .offering-img-row {
      width: 100%;
      max-width: 1200px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
      .offering-img-vert {
        max-height: 100vh;
        max-height: 800px;
        /* height: 100%; */
        width: calc(50% - 12.5px);
        margin: 50px 0;
      }
    }
  }
`;

// eslint-disable-next-line react/prop-types
const Faq = ({ data }) => (
  <Layout
    seo={{
      title: 'Naisten retket',
      url: 'https://www.discoverlapland.fi/usein-kysytyt-kysymykset',
      image: `https://www.discoverlapland.fi/${data.useinKysytytKysymyksetSEO.childImageSharp.gatsbyImageData.src}`,
      description: 'Usein kysytyt kysymykset',
    }}
  >
    <ThemeProvider theme={theme}>
      <FaqStyles>
        <GatsbyImage
          image={data.useinKysytytKysymykset.childImageSharp.gatsbyImageData}
          className="faq-header-image"
          alt="Usein kysytyt kysymykset"
        />
        <h2 className="faq-title">Usein kysytyt kysymykset!</h2>
        <div className="faq-content-container">
          <p>
            <span className="bolded">Voinko lähteä retkelle yksin?</span>
            <br />
            Totta kai voit! Suurin osa naisista tuleekin yksin. Toki on myös
            kavereita, jotka haluavat kokea yhdessä retki-iloa ohjatusti ja
            turvallisesti. Tulet sitten yksin tai kaverin kanssa, tulet
            tutustumaan upeisiin naisiin, joita kiinnostaa luonnossa
            liikkuminen. Moni on saanut meidän retkiltä uusia retkikavereita,
            mikäs sen mukavampaa!
            <br />
          </p>
          <br />
          <p>
            <span className="bolded">
              Miten pääsen retkitavaroideni kanssa Lappiin?
            </span>
            <br />
            Lappiin pääsee junalla, bussilla, lentäen sekä autolla.
            Suosittelemme yleensä autoilijoille kimppakyytejä, sillä säästää
            rahaa, luontoa sekä saa samalla hyvää matkaseuraa ja pääsette
            tutustumaan toisiinne jo ajomatkalla. Riippuen retken
            aloituspisteestä, näihin kaikkiin pääsee bussilla. Matkahuolto
            toimii Lapissa ja matkahuollon bussipysäkille pääsee junalla ja
            lentäen. Lentäen pääsee Rovaniemelle ja Kittilään, josta voi sitten
            hypätä bussiin ja jatkaa matkaa mm. Hettaan / Kilpisjärvelle,
            riippuen mille retkelle olet tulossa. Lentokoneeseen et voi ottaa
            mukaan retkikeittimen kaasuja / nesteitä, eli nämä sinun tulee ostaa
            Lapista / joku retkelle lähtijä tuo ne sinulle autolla.
            <br />
          </p>
          <br />
          <p>
            <span className="bolded">
              Mistä voin vuokrata vaellukseen tarvikkeita?
            </span>
            <br />
            Kaikkea ei tarvitse vaellukselle ostaa, helppo tapa on vuokraus. Kun
            olet saanut meiltä pakkauslistan, näet tarkasti mitä ja minkälaiset
            tarvikkeet tulee olla. Muoniossa telttoja, makuupusseja,
            makuualustoja sekä rinkkoja vuokraa{' '}
            <a className="bolded" href="https://laplandoutdoor.com/">
              https://laplandoutdoor.com/
            </a>{' '}
            Mutta moni muukin paikka vuokraa retkitarvikkeita, sinun tarvitsee
            vain googlata tarvikkeen nimi ja vuokraus, paikkoja on monia. Pidä
            vain huolta, kun lainaat tai vuokraat tarvikkeita, että ne eivät ole
            rikki ja täyttävät meidän pakkauslistan vaatimukset.
            <br />
          </p>
          <br />
          <p>
            <span className="bolded">
              Onko Lapissa kesällä paljon hyttysiä ja mäkäräisiä?
            </span>
            <br />
            Yleensä juhannuksen jälkeen alkaa Lapissa hyttysten aika
            (räkkäaika), joka kestää noin kuukauden. Monella on mielikuva, että
            koko Lappi on kesäaikaan painajaismainen hyttysten valtakunta, jonne
            ei kannata mennä lainkaan. Jos menet seisomaan suolle tai jängälle
            räkkäaikaan, siellä on varmasti paljon hyönteisiä sinua odottamassa.
            Tunturiylängössä, jossa retkemme pääasiassa tapahtumat, on yleensä
            tuulista, joka tarkoittaa sitä, että hyttyset ja mäkäräiset ei
            siellä viihdy.
            <br />
            <br />
            Räkkäaikaan kannattaa pukeutua peittävästi. Pitkähihainen paita,
            suojaavat housut, sukat, kengät ja päähine estävät hyttysiä
            pistämästä. Hyttys/sääskihuppu on tehokas suoja pään alueelle
            suojaamaan kasvot ja niska. Lierihatun tai lippiksen kanssa
            yhdistelmä toimii parhaiten, kun verkko on irti ihosta ja hyttynen
            ei yletä pistämään. Hyttyskarkote on myös hyvä olla mukana kesän
            vaelluksilla ja retkillä. Hyttysen pistoihin voit käyttää
            reseptivapaata allergialääkettä ja markkinoilla on myös kutinaa
            lieventäviä voiteita.
          </p>
          <br />
          <p>
            <span className="bolded">
              Jos sairastun tai joudun muusta syystä perumaan osallistumiseni
              juuri ennen retkeä, mitkä ovat peruutusehdot?
            </span>
            <br />
            Pidä huoli, että sinulla on oma matkavakuutus tai
            vapaa-ajanvakuutus.{' '}
            <Link className="bolded" to="/varaus-ja-peruutusehdot">
              Tästä
            </Link>{' '}
            pääset katsomaan kaikkien retkiemme varaus- ja peruutusehdot.
            <br />
          </p>
          <br />
          <p>
            <span className="bolded">
              Mitä tarkoittaa aloittelijoiden retki?
            </span>
            <br />
            Tämä tarkoittaa sitä, että sinulla ei tarvitse olla aikaisempaa
            kokemusta vaelluksesta, hiihtovaelluksesta tai maastopyöräilystä,
            riippuen mille retkelle olet tulossa. Me valmistamme sinut retkelle
            materiaalein sekä kahdella eri online-tapaamisella. Aloittelija ei
            tarkoita, että olet aloitteleva liikkumaan. Sinun tulee olla hyvässä
            fyysisessä kunnossa, retkille ei voi ponnistaa sohvan pohjalta.
            Vaikka retkillä nautitaankin maisemista, tunnelmasta, vapaudesta ja
            olet lomalla, ryhmän tulee edetä päivän aikana ja maasto on
            haastavaa, mutta ei ylitsepääsemätöntä.
            <br />
          </p>
          <br />
        </div>
      </FaqStyles>
    </ThemeProvider>
  </Layout>
);

export const query = graphql`
  {
    useinKysytytKysymykset: file(
      relativePath: { eq: "images/mtb/pahakuru-6.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    useinKysytytKysymyksetSEO: file(
      relativePath: { eq: "images/mtb/pahakuru-6.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
  }
`;

export default Faq;
